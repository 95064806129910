#contact {
  background-image: url("../../assets/images/backgrounds/green-gradient\(s\).jpg");
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact-name {
  font-family: Tenor Sans;
  text-transform: uppercase;
  font-size: 1.1rem;
}

.contact-heading {
  font-family: "Dream Avenue";
  font-size: 5rem !important;
  color: var(--yellow);
  text-transform: uppercase;
  line-height: 100%;
  letter-spacing: 1px;
}

.bottom {
  position: absolute;
  bottom: 0;
}

.social-groups {
  color: #eeecde;
}

.social-groups h4 {
  font-family: Tenor Sans;
  text-transform: uppercase;
  font-size: 1.1rem;
  padding-bottom: 3px;
}

.soc-links a {
  color: var(--bs-heading-color);
  font-family: "Tenor Sans";
  text-decoration: none;
  font-size: 1rem;
}

.soc-links a:hover {
  text-decoration: overline;
  color: #cbb768;
  cursor: pointer;
}

@media screen and (max-width: 576px) {
  .contact-name {
    font-size: 0.9rem;
  }
  .contact-heading {
    font-size: 3.5rem !important;
    margin-top: 120px;
  }
}

@media screen and (min-width: 1440px) {
  .contact-name {
    font-size: 1.4rem;
  }
  .contact-heading {
    font-size: 6.5rem !important;
  }
  .social-groups h4 {
    font-size: 1.3rem;
    padding-bottom: 5px;
  }
  .soc-links a {
    font-size: 1.2rem;
  }
}
