body,
html {
  height: 100vh;
  width: 100vw;
  margin: 0;
}

:root {
  --yellow: #cbb768;
  --darkyellow: #d4b12a;
}

@font-face {
  font-family: "Dream Avenue";
  src: url("/src/assets/fonts/Dream-Avenue.ttf") format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@10..48,300;10..48,400;10..48,500;10..48,600;10..48,800&family=Inter:wght@300;400;500&display=swap");
