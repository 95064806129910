.bricolage {
  font-family: "Bricolage Grotesque", sans-serif;
  font-weight: 500;
  letter-spacing: -1px;
}

.sub-heading-a {
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

.sub-headings-b {
  font-family: "Inter", sans-serif;
  font-weight: 300;
}

.sub-headings-c {
  font-family: "Inter", sans-serif;
  font-weight: 300;
}

@media screen and (max-width: 768px) {
  #auto-form-title {
    width: 100%;
  }
  .bricolage {
    font-size: 60px;
  }
}

@media screen and (min-width: 768px) {
  .contract-container {
    padding-top: 140px !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 576px) {
  .sub-box {
    max-width: 200px;
  }
  .bricolage {
    font-size: 50px;
  }
  .sub-heading-a {
    font-size: 30px;
    font-weight: 500;
  }
  .sub-headings-b {
    font-family: "Inter", sans-serif;
    font-size: 20px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .sub-heading-a {
    font-size: 43px;
  }
  .sub-box {
    max-width: 390px;
  }
  .sub-box-1 {
    max-width: 330px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .bricolage {
    font-size: 60px;
  }
  .sub-heading-a {
    font-size: 45px;
  }
  .sub-headings-b {
    font-family: "Inter", sans-serif;
    font-size: 30px;
  }
  #subC1 {
    font-family: 14px !important;
  }
  .sub-box {
    max-width: 400px;
  }
  .sub-box-1 {
    max-width: 350px;
  }
}

@media screen and (min-width: 1200px) {
  .contract-container {
    width: 450px;
  }
  .contract-container-home {
    width: 800px;
  }

  .sub-heading-a {
    font-size: 50px;
  }
  .sub-headings-b {
    font-size: 40px;
  }
  .sub-headings-c {
    font-size: 30px;
  }

  .sub-box {
    max-width: 480px;
  }

  .bricolage {
    font-size: 75px;
  }
}
