.nav-link {
  color: black !important;
  font-family: "Tenor Sans";
}

.nav-link:hover {
  text-decoration: overline;
  color: #cbb768 !important;
  cursor: pointer;
  text-transform: uppercase;
}
