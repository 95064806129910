#accordion {
  max-width: 80vw;
  color: white;
}

.accordion-name {
  color: var(--yellow);
  font-family: "Tenor Sans";
  text-transform: uppercase;
  font-size: 19px !important;
  /* max-height: 38px; */
}

.accordion-name-box:hover {
  cursor: pointer;
}

.accordion-name-box i {
  transition: 0.2s ease-in-out;
}

.type {
  font-family: "Tenor Sans";
  font-size: 14px;
}

.accordion-desc {
  font-family: "DM Sans", sans-serif;
}

.accordion-item {
  overflow: hidden;
  max-height: "70px";
}

.desc-text {
  /* font-size: 14px; */
  font-weight: 100;
}

.desc {
  opacity: 0.75 !important;
}

.site-links {
  font-family: "Tenor Sans";
  /* font-size: 13px; */
  color: var(--darkyellow);
}

.project-img {
  width: 85%;
  margin: auto;
  display: block;
}

.chevron-icon {
  transition: 0.2s ease-in-out;
  transform-origin: center;
}

.lin {
  text-decoration: none !important;
}

@media screen and (min-width: 425px) and (max-width: 510px) {
  .project-img {
    width: 70% !important;
    margin: auto;
  }
}

@media screen and (min-width: 510px) and (max-width: 768px) {
  .project-img {
    width: 60% !important;
    margin: auto;
  }
}

@media screen and (min-width: 768px) {
  .accordion-name {
    font-size: 25px !important;
  }
  #accordion {
    max-width: 55vw;
    color: white;
  }

  .type {
    font-family: "Tenor Sans";
    font-size: 16px;
  }
  .desc-text {
    font-size: 1rem;
  }
  .project-img {
    width: 100% !important;
    margin: auto;
  }
  .site-links {
    font-size: 14px;
  }
}

@media screen and (max-width: 565px) {
  .accordion-name {
    line-height: 18px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1060px) {
  .adjusted-text {
    line-height: 25px;
    font-size: 2.5vw !important;
    margin-top: -5px;
  }
}

@media screen and (max-width: 576px) {
  .type {
    font-family: "Tenor Sans";
    font-size: 10px;
  }

  .desc-text {
    font-size: 14px;
  }

  .site-links {
    font-size: 14px;
  }

  .desc {
    padding-left: 6px;
  }
}
